import React, { useEffect, useRef } from 'react';
import * as bytemd from 'bytemd';
export const Editor = ({ children, onChange, ...props }) => {
    const ed = useRef();
    const el = useRef(null);
    useEffect(() => {
        if (!el.current)
            return;
        const editor = new bytemd.Editor({
            target: el.current,
            props,
        });
        editor.$on('change', (e) => {
            onChange === null || onChange === void 0 ? void 0 : onChange(e.detail.value);
        });
        ed.current = editor;
        return () => {
            editor.$destroy();
        };
    }, []);
    useEffect(() => {
        var _a;
        // TODO: performance
        (_a = ed.current) === null || _a === void 0 ? void 0 : _a.$set(props);
    }, [props]);
    return React.createElement("div", { ref: el });
};
