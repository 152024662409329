<svelte:options immutable={true} /><script>;
;
import { tick, onDestroy, onMount, createEventDispatcher } from 'svelte';
import { getProcessor } from './utils';
const dispatch = createEventDispatcher();
export let value = '';
export let plugins = [];
export let sanitize;
let markdownBody;
let cbs = [];
function on() {
    // console.log('von');
    cbs = plugins.map((p) => { var _a; return (_a = p.viewerEffect) === null || _a === void 0 ? void 0 : _a.call(p, { markdownBody, file }); });
}
function off() {
    // console.log('voff');
    cbs.forEach((cb) => cb && cb());
}
onMount(() => {
    markdownBody.addEventListener('click', (e) => {
        var _a;
        const $ = e.target;
        if ($.tagName !== 'A')
            return;
        const href = $.getAttribute('href');
        if (!(href === null || href === void 0 ? void 0 : href.startsWith('#')))
            return;
        (_a = markdownBody
            .querySelector('#user-content-' + href.slice(1))) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
    });
});
onDestroy(off);
let file;
let i = 0;
$: try {
    file = getProcessor({
        sanitize,
        plugins: [
            ...plugins,
            {
                // remark: (p) =>
                //   p.use(() => (tree) =>{
                //     console.log(tree)
                //   }),
                rehype: (p) => p.use(() => (tree, file) => {
                    tick().then(() => {
                        // console.log(tree);
                        dispatch('hast', { hast: tree, file });
                    });
                }),
            },
        ],
    }).processSync(value);
    i++;
    off();
    tick().then(() => {
        on();
    });
}
catch (err) {
    console.error(err);
}
$: html = `${file}<!--${i}-->`;
</script><div bind:this={markdownBody} class="markdown-body">{@html html}</div>
