<svelte:options immutable={true} /><script>var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
;
;
;
;
import { onMount, createEventDispatcher, onDestroy, tick } from 'svelte';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import Toolbar from './toolbar.svelte';
import Viewer from './viewer.svelte';
import Toc from './toc.svelte';
import { createEditorUtils, findStartIndex, getBuiltinActions, handleImageUpload, } from './editor';
import Status from './status.svelte';
import { icons } from './icons';
import en from './locales/en.json';
import deepmerge from 'deepmerge';
import Help from './help.svelte';
import factory from 'codemirror-ssr';
import usePlaceholder from 'codemirror-ssr/addon/display/placeholder';
import useOverlay from 'codemirror-ssr/addon/mode/overlay.js';
import useXml from 'codemirror-ssr/mode/xml/xml';
import useMarkdown from 'codemirror-ssr/mode/markdown/markdown';
import useGfm from 'codemirror-ssr/mode/gfm/gfm';
import useYaml from 'codemirror-ssr/mode/yaml/yaml';
import useYamlFrontmatter from 'codemirror-ssr/mode/yaml-frontmatter/yaml-frontmatter';
import useVim from 'codemirror-ssr/keymap/vim';
import useEmacs from 'codemirror-ssr/keymap/emacs';
import useLint from 'codemirror-ssr/addon/lint/lint';
export let value = '';
export let plugins = [];
export let sanitize;
export let mode = 'auto';
export let previewDebounce = 300;
export let placeholder;
export let editorConfig;
export let locale;
export let uploadImages;
export let overridePreview;
// do deep merge to support incomplete locales, use en as fallback
$: mergedLocale = deepmerge(en, locale !== null && locale !== void 0 ? locale : {});
const dispatch = createEventDispatcher();
$: actions = getBuiltinActions(mergedLocale, plugins, uploadImages);
$: split = mode === 'split' || (mode === 'auto' && containerWidth >= 800);
$: ((_) => {
    // reset active tab
    if (split)
        activeTab = false;
})(split);
let root;
let editorEl;
let previewEl;
let containerWidth = Infinity; // TODO: first screen
let codemirror;
let editor;
let activeTab;
let fullscreen = false;
let sidebar = false;
$: styles = (() => {
    let edit;
    let preview;
    if (split && activeTab === false) {
        if (sidebar) {
            edit = `width:calc(50% - ${sidebar ? 140 : 0}px)`;
            preview = `width:calc(50% - ${sidebar ? 140 : 0}px)`;
        }
        else {
            edit = 'width:50%';
            preview = 'width:50%';
        }
    }
    else if (activeTab === 'preview') {
        edit = 'display:none';
        preview = `width:calc(100% - ${sidebar ? 280 : 0}px)`;
    }
    else {
        edit = `width:calc(100% - ${sidebar ? 280 : 0}px)`;
        preview = 'display:none';
        // TODO: use width:0 to make scroll sync work until
        // the position calculation improved (causes white screen after switching to editor only)
    }
    return { edit, preview };
})();
$: context = (() => {
    const context = Object.assign({ 
        // @ts-ignore
        codemirror,
        editor,
        root }, createEditorUtils(codemirror, editor));
    return context;
})();
let cbs = [];
let keyMap = {};
function on() {
    // console.log('on', plugins);
    cbs = plugins.map((p) => { var _a; return (_a = p.editorEffect) === null || _a === void 0 ? void 0 : _a.call(p, context); });
    keyMap = {};
    // TODO: nested shortcuts
    actions.forEach(({ handler }) => {
        if ((handler === null || handler === void 0 ? void 0 : handler.type) === 'action' && handler.shortcut) {
            keyMap[handler.shortcut] = () => {
                handler.click(context);
            };
        }
    });
    editor.addKeyMap(keyMap);
}
function off() {
    // console.log('off', plugins);
    cbs.forEach((cb) => cb && cb());
    editor.removeKeyMap(keyMap);
}
let debouncedValue = value;
const setDebouncedValue = debounce((value) => {
    debouncedValue = value;
    overridePreview === null || overridePreview === void 0 ? void 0 : overridePreview(previewEl, { value: debouncedValue, plugins, sanitize });
}, previewDebounce);
$: setDebouncedValue(value);
$: if (editor && value !== editor.getValue()) {
    editor.setValue(value);
}
$: if (editor && plugins) {
    off();
    tick().then(() => {
        on();
    });
}
// Scroll sync vars
let syncEnabled = true;
let editCalled = false;
let previewCalled = false;
let editPs;
let previewPs;
let hast = { type: 'root', children: [] };
let vfile;
let currentBlockIndex = 0;
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    codemirror = factory();
    usePlaceholder(codemirror);
    useOverlay(codemirror);
    useXml(codemirror); // inline html highlight
    useMarkdown(codemirror);
    useGfm(codemirror);
    useYaml(codemirror);
    useYamlFrontmatter(codemirror);
    useVim(codemirror);
    useEmacs(codemirror);
    useLint(codemirror);
    // lint
    const linter = () => {
        if (!vfile)
            return [];
        const annotations = vfile.messages.map((m) => {
            const a = {
                from: codemirror.Pos(m.location.start.line - 1, m.location.start.column - 1),
                to: m.location.end.line == null // TODO: why null?
                    ? codemirror.Pos(m.location.start.line - 1)
                    : codemirror.Pos(m.location.end.line - 1, m.location.end.column - 1),
                message: m.message,
            };
            return a;
        });
        // console.log(annotations);
        return annotations;
    };
    // @ts-ignore TODO: type
    editor = codemirror(editorEl, Object.assign(Object.assign({ value, mode: 'yaml-frontmatter', lineWrapping: true, tabSize: 8, indentUnit: 4 }, editorConfig), { placeholder, lint: linter }));
    // https://github.com/codemirror/CodeMirror/issues/2428#issuecomment-39315423
    // https://github.com/codemirror/CodeMirror/issues/988#issuecomment-392232020
    editor.addKeyMap({
        Tab: 'indentMore',
        'Shift-Tab': 'indentLess',
    });
    editor.on('change', (doc, change) => {
        dispatch('change', { value: editor.getValue() });
    });
    const updateBlockPositions = throttle(() => {
        editPs = [];
        previewPs = [];
        const scrollInfo = editor.getScrollInfo();
        const body = previewEl.childNodes[0];
        if (!(body instanceof HTMLElement))
            return;
        const leftNodes = hast.children.filter((v) => v.type === 'element');
        const rightNodes = [...body.childNodes].filter((v) => v instanceof HTMLElement);
        for (let i = 0; i < leftNodes.length; i++) {
            const leftNode = leftNodes[i];
            const rightNode = rightNodes[i];
            // if there is no position info, move to the next node
            if (!leftNode.position) {
                continue;
            }
            const left = editor.heightAtLine(leftNode.position.start.line - 1, 'local') /
                (scrollInfo.height - scrollInfo.clientHeight);
            const right = (rightNode.offsetTop - body.offsetTop) /
                (previewEl.scrollHeight - previewEl.clientHeight);
            if (left >= 1 || right >= 1) {
                break;
            }
            editPs.push(left);
            previewPs.push(right);
        }
        editPs.push(1);
        previewPs.push(1);
        // console.log(editPs, previewPs);
    }, 1000);
    const editorScrollHandler = () => {
        if (overridePreview)
            return;
        if (!syncEnabled)
            return;
        if (previewCalled) {
            previewCalled = false;
            return;
        }
        updateBlockPositions();
        const info = editor.getScrollInfo();
        const leftRatio = info.top / (info.height - info.clientHeight);
        const startIndex = findStartIndex(leftRatio, editPs);
        const rightRatio = ((leftRatio - editPs[startIndex]) *
            (previewPs[startIndex + 1] - previewPs[startIndex])) /
            (editPs[startIndex + 1] - editPs[startIndex]) +
            previewPs[startIndex];
        // const rightRatio = rightPs[startIndex]; // for testing
        previewEl.scrollTo(0, rightRatio * (previewEl.scrollHeight - previewEl.clientHeight));
        editCalled = true;
    };
    const previewScrollHandler = () => {
        if (overridePreview)
            return;
        // find the current block in the view
        updateBlockPositions();
        currentBlockIndex = findStartIndex(previewEl.scrollTop / (previewEl.scrollHeight - previewEl.offsetHeight), previewPs);
        if (!syncEnabled)
            return;
        if (editCalled) {
            editCalled = false;
            return;
        }
        const rightRatio = previewEl.scrollTop / (previewEl.scrollHeight - previewEl.clientHeight);
        const startIndex = findStartIndex(rightRatio, previewPs);
        const leftRatio = ((rightRatio - previewPs[startIndex]) *
            (editPs[startIndex + 1] - editPs[startIndex])) /
            (previewPs[startIndex + 1] - previewPs[startIndex]) +
            editPs[startIndex];
        const info = editor.getScrollInfo();
        editor.scrollTo(0, leftRatio * (info.height - info.clientHeight));
        previewCalled = true;
    };
    editor.on('scroll', editorScrollHandler);
    previewEl.addEventListener('scroll', previewScrollHandler, {
        passive: true,
    });
    // handle image drop and paste
    const handleImages = (e, itemList) => __awaiter(void 0, void 0, void 0, function* () {
        if (!uploadImages)
            return;
        const files = Array.from(itemList !== null && itemList !== void 0 ? itemList : [])
            .map((item) => {
            if (item.type.startsWith('image/')) {
                return item.getAsFile();
            }
        })
            .filter((f) => f != null);
        if (files.length) {
            e.preventDefault(); // important
            yield handleImageUpload(context, uploadImages, files);
        }
    });
    editor.on('drop', (_, e) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        handleImages(e, (_a = e.dataTransfer) === null || _a === void 0 ? void 0 : _a.items);
    }));
    editor.on('paste', (_, e) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        handleImages(e, (_b = e.clipboardData) === null || _b === void 0 ? void 0 : _b.items);
    }));
    // @ts-ignore
    new ResizeObserver((entries) => {
        containerWidth = entries[0].contentRect.width;
        // console.log(containerWidth);
    }).observe(root, { box: 'border-box' });
    // No need to call `on` because cm instance would change once after init
}));
onDestroy(off);
</script><div
  class="bytemd"
  class:bytemd-split={split && activeTab === false}
  class:bytemd-fullscreen={fullscreen}
  bind:this={root}
><Toolbar
    {context}
    {split}
    {activeTab}
    {sidebar}
    {fullscreen}
    locale={mergedLocale}
    {actions}
    on:key={(e) =>{
      editor.setOption('keyMap', e.detail);
      editor.focus();
    }}
    on:tab={(e) =>{
      const v = e.detail;
      if (split) {
        activeTab = activeTab === v ? false : v;
      } else {
        activeTab = v;
      }

      if (activeTab === 'write') {
        tick().then(() =>{
          editor && editor.focus();
        });
      }
    }}
    on:click={(e) =>{
      switch (e.detail) {
        case 'fullscreen':
          fullscreen = !fullscreen;
          break;
        case 'help':
          sidebar = sidebar === 'help' ? false : 'help';
          break;
        case 'toc':
          sidebar = sidebar === 'toc' ? false : 'toc';
          break;
      }
    }}
  /><div class="bytemd-body"><div class="bytemd-editor" style={styles.edit} bind:this={editorEl} /><div bind:this={previewEl} class="bytemd-preview" style={styles.preview}>{#if !overridePreview}<Viewer
          value={debouncedValue}
          {plugins}
          {sanitize}
          on:hast={(e) =>{
            hast = e.detail.hast;
            vfile = e.detail.file;
          }}
        />{/if}</div><div class="bytemd-sidebar" class:bytemd-hidden={sidebar === false}><div
        class="bytemd-sidebar-close"
        on:click={() =>{
          sidebar = false;
        }}
      >{@html icons.close}</div><Help locale={mergedLocale} {actions} visible={sidebar === 'help'} /><Toc
        {hast}
        locale={mergedLocale}
        {currentBlockIndex}
        on:click={(e) =>{
          const headings = previewEl.querySelectorAll('h1,h2,h3,h4,h5,h6');
          headings[e.detail].scrollIntoView();
        }}
        visible={sidebar === 'toc'}
      /></div></div><Status
    locale={mergedLocale}
    showSync={!overridePreview && split}
    value={debouncedValue}
    {syncEnabled}
    on:sync={(e) =>{
      syncEnabled = e.detail;
    }}
    on:top={() =>{
      editor.scrollTo(null, 0);
      previewEl.scrollTo({ top: 0 });
    }}
  /></div>
